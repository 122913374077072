<template>
  <div class="distributorInfo">
    <van-nav-bar
      title="分销员信息"
      left-arrow
      @click-left="onClickLeft"
      class="dark_bg"
    />
    <div class="content">
      <div class="userInfo">
        <div class="avatar">
          <img class="user-img" :src="userInfo.avatarUrl" alt />
        </div>
        <div class="right_info">
          <div class="nickName">{{ userInfo.nickname }}</div>
          <div class="info" v-if="storeName">当前店铺：{{ storeName }}</div>
          <div class="info">手机号：{{ distributorInfo.mobile }}</div>
        </div>
      </div>
      <div class="earnings">
        <div class="earnings_top">
          <div class="left part">
            <div class="txt">可提现金额</div>
            <div class="num">{{ distributorInfo.money }}元</div>
          </div>
          <div class="right part">
            <div class="txt">总收益</div>
            <div class="num">{{ distributorInfo.maxMoney }}元</div>
          </div>
          <!-- <div class="left part">
            <div class="txt">今日订单数量</div>
            <div class="num">{{ distributorInfo.todayCountOrder }}元</div>
          </div>
          <div class="right part">
            <div class="txt">累计订单数量</div>
            <div class="num">{{ distributorInfo.countOrder }}元</div>
          </div>
          <div class="left part">
            <div class="txt">今日客户数量</div>
            <div class="num">{{ distributorInfo.todayCountClient }}元</div>
          </div>
          <div class="right part">
            <div class="txt">累计客户数量</div>
            <div class="num">{{ distributorInfo.countClient }}元</div>
          </div> -->
        </div>
        <div class="earnings_top">
          <div class="left part">
            <div class="txt">今日订单数量</div>
            <div class="num">{{ distributorInfo.todayCountOrder }}件</div>
          </div>
          <div class="right part">
            <div class="txt">累计订单数量</div>
            <div class="num">{{ distributorInfo.countOrder }}件</div>
          </div>
        </div>
        <div class="earnings_top">
          <div class="left part">
            <div class="txt">今日客户数量</div>
            <div class="num">{{ distributorInfo.todayCountClient }}人</div>
          </div>
          <div class="right part">
            <div class="txt">累计客户数量</div>
            <div class="num">{{ distributorInfo.countClient }}人</div>
          </div>
        </div>
        <div class="earnings_bottom">
          <div class="look_order" @click="inviteDistributor()">邀请成为二级分销员</div>
          <div class="look_order" @click="lookOrder()">查看分享出去的订单</div>
        </div>
        
      </div>
      <!-- <van-button class="look_order" @click="lookOrder()">查看分享出去的订单</van-button> -->
      <!-- <div class="more" @click="toggleShowMore">展开更多</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      distributorInfo: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      avatarUrl: "",
      storeName: "",
    };
  },
  created() {
    this.getDistributorInfo()
    this.getStoreName()
  },
  mounted() {},
  methods: {
    toggleShowMore() {
    },
    inviteDistributor() {
      this.$router.push({
        path: "/inviteDistributor",
        query: {
          distributionStoreId: this.distributorInfo.distributionStoreId
        }
      });
    },
    lookOrder() {
      this.$router.push({
        path: "/distributorOrder",
        // query: {
        // }
      });
    },
    getDistributorInfo() {
      let storeId = localStorage.getItem("storeId");
      this.$api.distributor
        .getCommissionUserInfo({
          // accessToken: localStorage.getItem("token"),
          storeId: storeId != "null" && storeId ? storeId : 0,
          userId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          console.log("获取分销员信息", res);
          if (res.errno == 200) {
            this.distributorInfo = res.data;
            // this.getStoreName(res.data.distributionStoreId);
            // this.$toast({
            //   message: "申请成功",
            //   duration: 1000,
            // });
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 获取店铺名称
    getStoreName() {
      let getStoreId = localStorage.getItem("storeId");
      let storeId = getStoreId != 'null' && getStoreId ? getStoreId : 0
      this.$api.home.getStoreInfo(storeId).then((res) => {
        if (res.errno === 200) {
          // this.$set(this.distributorInfo, 'storeName', res.data.storeName)
          if(res.data) {
            this.storeName = res.data.storeName;
          }else {
            this.storeName = '云楚新能源';
          }
        }
      });
    },
    onClickLeft() {
      this.$router.back();
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.distributorInfo {
  width: 100vw;
  height: 100vh;
  background: #fff;
}
.userInfo {
  width: 100vw;
  box-sizing: border-box;
  background: #242632;
  color: #fff;
  padding: 0.3rem /* 15/50 */;
  font-size: 0.4rem /* 20/50 */;
  display: flex;
  align-items: center;
  .avatar {
    .user-img {
      width: 1.23rem;
      height: 1.23rem;
      border: 0.04rem solid #ffffff;
      box-shadow: 0px 0px 0.18rem 0px rgba(0, 0, 0, 0.57);
      border-radius: 50%;
      background: #eee;
      margin-right: 0.3rem /* 15/50 */;
    }
  }
  .nickName {
    margin-bottom: 12px;
  }
  .info {
    font-size: 0.24rem /* 12/50 */;
    line-height: 1.5;
  }
}
.earnings {
  padding: 0.3rem /* 15/50 */;
  width: 92vw;
  box-sizing: border-box;
  background: #ed9a3c;
  color: #fff;
  font-size: 0.24rem /* 12/50 */;
  margin: 20px auto;
  border-radius: 0.2rem /* 10/50 */;
  .earnings_top {
    display: flex;
    margin-bottom: 20px;
  }
  .part {
    width: 50%;
  }
  .txt {
    margin-bottom: 10px;
  }
  .num {
    font-size: 0.32rem /* 16/50 */;
  }
  .earnings_bottom {
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    line-height: 2;
    margin-top: .6rem /* 30/50 */;
  }
}
.look_order {
  // color: #ed9a3c;
  font-size: 0.28rem /* 14/50 */;
  // margin-left: 4vw;
}
</style>
